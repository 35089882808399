<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('withdraw.with_record')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <div class="subtitle-box">
        <span class="subtitle-span">{{ $t('提现日期') }}</span>
        <span class="subtitle-span">{{ $t('提现金额') }}</span>
        <span class="subtitle-span">{{ $t('状态') }}</span>
        <!-- <span class="subtitle-span">详情</span> -->
      </div>
      <van-pull-refresh pulling-text="새로고침 성공" loosing-text="페이지 새로 고침" loading-text="새로고침 성공" success-text="새로고침 성공"
        v-model="isLoading" @refresh="onRefresh">
        <van-empty v-if="list.length === 0" :description="$t('withdraw.empty_data')" />
        <div v-else class="item_list" v-for="(v, key) in list" :key="key">
          <div class="topInfo">
            <span>{{ v.create_time }}</span>
            <span class="span-left">{{ v.money }}</span>
            <span class="span-left">{{ v.status_text }}</span>
            <!-- <i data-v-804c1008="" class="van-badge__wrapper van-icon van-icon-arrow arrow" style="font-size: 14px;"></i> -->
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: []
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t("reservation.refresh"));
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList() {
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_list'
      }).then(res => {
        if (res.code === 200) {
          this.list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' })
    } else {
      this.getUserWithdrawList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.span-left{
  text-align: center;
}
.subtitle-box {
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 3rem;
  font-size: 20px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color:hsla(0,0%,100%,.8);
}

.container .main {
  position: relative;
  overflow: auto;
  background-color: #29243D;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: #342f46;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 25px;
  font-weight: 700;
  color: #fff;
}
.topInfo{
  display: flex;
    height: 3rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #fff;
}

.item_list .topInfo span:last-child {
  float: right;
}

.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
</style>
