<template>
	<div class="container page">
		<van-nav-bar :title="$t('setting.language')" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="items">
			<!-- <div
        class="item van-hairline--bottom"
        @click="changeLang('zh_cn', $event)"
      >
        <div class="flex_center">
          <div><img src="../images/language/zh_cn.png" /></div>
          <div class="info">简体中文</div>
        </div>
        <div v-if="lang == 'zh_cn'">✓</div>
      </div> -->
			<!-- <div class="item van-hairline--bottom" @click="changeLang('ko_hy', $event)">
				<div class="flex_center">
					<div><img src="../images/language/ko_hy.jpg" /></div>
					<div class="info">한국인</div>
				</div>
				<div v-if="lang == 'ko_hy'">✓</div>
			</div> -->
			<div class="item van-hairline--bottom" @click="changeLang('ja_jp', $event)">
				<div class="flex_center">
					<div><img style="height: auto !important;margin-top:8px;" src="../images/language/ja_jp.jpg" /></div>
					<div class="info">日本语</div>
				</div>
				<div v-if="lang == 'ja_jp'">✓</div>
			</div>
			<div class="item van-hairline--bottom" @click="changeLang('en_us', $event)">
				<div class="flex_center">
					<div><img src="../images/language/en_us.png" /></div>
					<div class="info">English</div>
				</div>
				<div v-if="lang == 'en_us'">✓</div>
			</div>

			<!-- <div
        class="item van-hairline--bottom"
        @click="changeLang('es_spa', $event)"
      >
        <div class="flex_center">
          <div><img src="../images/language/es_spa.png" /></div>
          <div class="info">España</div>
        </div>
        <div v-if="lang == 'es_spa'">✓</div>
      </div>

      <div
        class="item van-hairline--bottom"
        @click="changeLang('ms_my', $event)"
      >
        <div class="flex_center">
          <div><img src="../images/language/ms_my.png" /></div>
          <div class="info">Melayu</div>
        </div>
        <div v-if="lang == 'ms_my'">✓</div>
      </div>
      <div
        class="item van-hairline--bottom"
        @click="changeLang('yn_yu', $event)"
      >
        <div class="flex_center">
          <div><img src="../images/language/yn_yu.png" /></div>
          <div class="info">Tiếng Việt</div>
        </div>
        <div v-if="lang == 'yn_yu'">✓</div>
      </div> -->
		</div>
	</div>
</template>

<script>
	import {
		Toast
	} from "vant";
	export default {
		name: "Language",
		data() {
			return {
				lang: this.$i18n.locale || "es_spa",
				source: ''
			};
		},
		created() {},
		mounted() {
			this.source = this.$route.params.type
		},
		methods: {
			back() {
				return window.history.back();
			},
			changeLang(lang) {

				Toast.loading({
					// mask: true,
					duration: 200,
				});
				this.lang = lang;
				this.$i18n.locale = lang;
				localStorage.setItem("lang", lang);
				if (this.source == 'setting') {
					this.$router.push({
						path: '/'
					});
				} else {
					this.$router.go(-1);
				}
			},
		},
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	.container .items {
		background-color: #fff;
		font-size: 30px;
		color: #000;
		padding: 0 25px;
	}

	.container .items .item {
		padding: 10px 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom-width: 10px;
	}

	.container .items .van-hairline--bottom::after {
		border-bottom-width: 3px;
	}

	.container .sign-out {
		margin: 500px 20px 0;
		height: 100px;
		line-height: 100px;
		border-radius: 50px;
		color: #fff;
		font-size: 40px;
		font-weight: bolder;
		border: none;
		background: linear-gradient(270deg, #988fba, #f487e0);
	}

	.container .item .desc {
		font-size: 30px;
		font-weight: 700;
		color: #979799;
	}

	.container .item .right {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.flex_center {
		display: flex;
		align-items: center;
	}

	.flex_center img {
		width: 60px;
		height: 60px;
		margin-right: 20px;
	}
</style>