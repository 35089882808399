<template>
  <div class="container page">
    <van-nav-bar :title="$t('setting.fill_bank')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main-box">
      <!-- 表单切换选项 -->
      <div class="form-switch">
        <div class="click-select" @click="onFormTypeChange('bank')">
          <span :class="{'active': formType === 'bank'}">{{$t("银行")}}</span>
        </div>
        <div class="click-select" @click="onFormTypeChange('weixin')">
          <span :class="{'active': formType === 'weixin'}">{{$t("微信或支付宝")}}</span>
        </div>
      </div>

      <van-cell-group v-if="formType === 'bank'">
        <van-field class="inputfiled" v-model="bank" :label="$t('setting.band_name')" readonly :placeholder="$t('setting.band_name_tip')" @click="showSelectBanks()" />
        <van-field class="inputfiled" v-model="bankid" :label="$t('setting.band_account')" type="digit" :placeholder="$t('setting.band_account_tip')" />
        <van-field class="inputfiled" v-model="username" :label="$t('setting.username')" :placeholder="$t('setting.username_place')" />
        <van-field class="inputfiled" v-model="opw" type="password" :label="$t('setting.money_place')" :placeholder="$t('setting.money_place')" />
        <van-field class="inputfiled" v-model="tpw" type="password" :label="$t('setting.money_again_place')" :placeholder="$t('setting.money_again_place')" />
        <van-field class="inputfiled" v-model="mobile" :label="$t('setting.mobile')" type="digit" :placeholder="$t('setting.mobile_place')" />
      </van-cell-group>

      <van-cell-group v-else>
        <van-field class="inputfiled" v-model="username" :label="$t('真实姓名')" :placeholder="$t('请输入真实姓名')" />
        <van-field class="inputfiled" v-model="weixinAccount" :label="$t('微信或支付宝账号')" :placeholder="$t('请输入微信或支付宝账号')" />
        <van-field class="inputfiled" v-model="selectedBank" :label="$t('兑换银行')" readonly :placeholder="$t('选择请选择兑换银行收款码图片')" @click="showSelectBanks()" />
        
        <div class="upload-container">
          <van-uploader
            :after-read="onUploadQRCode"
            accept="image/*"
            :max-count="1"
            :show-upload="!qrCode"
          >
            <van-button type="info">{{ qrCode ? $t('收款码已上传') : $t('选择收款码图片') }}</van-button>
          </van-uploader>
        </div>
      </van-cell-group>

      <p>{{$t("setting.bank_warn")}}</p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{$t("setting.bank_ok")}}</van-button>
    
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }">
      <van-picker
        show-toolbar
        :columns="banks"
        @confirm="onConfirm"
        @cancel="onCancel"
        :confirm-button-text="$t('setting.ok')"
        :cancel-button-text="$t('setting.cancel')"
      />
    </van-popup>
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      formType: 'bank', // 表单类型，默认为银行
      banks: [],
      showBank: false,
      userInfo: {},
      bankid: "",
      username: "",
      mobile: "",
      bank: "",
      bank_code: "",
      opw: '',
      tpw: '',
      weixinAccount: "",
      selectedBank: "",
      qrCode: null,
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    bindCard() {
      if (this.formType === 'bank') {
        if (!this.bank || !this.bankid || !this.username || !this.opw || this.opw !== this.tpw || !this.mobile) {
          this.$toast(this.$t("请填写完整信息"));
          return false;
        }
      } else {
        if (!this.username || !this.weixinAccount || !this.selectedBank) {
          this.$toast(this.$t("请填写完整信息"));
          return false;
        }
      }

      this.$http({
        method: 'post',
        data: {
          bankid: this.bankid,
          bank: this.bank,
          bank_code: this.bank_code,
          username: this.username,
          mobile: this.mobile,
          opw: this.opw,
          tpw: this.tpw,
          weixin_account: this.weixinAccount,
          qr_code: this.qrCode,
          selected_bank: this.selectedBank,
        },
        url: 'user_set_bank'
      }).then(res => {
        if (res.code === 200) {
          this.$router.push({ path: '/Mine' });
          this.$toast(res.msg);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    showSelectBanks() {
      if (this.formType === 'bank') {
        this.getBankList();
      } else {
        this.banks = [
          { text: this.$t('支付宝'), value: 'alipay' },
          { text: this.$t('微信'), value: 'weixin' },
        ];
      }
      this.showBank = true;
    },
    onFormTypeChange(value) {
      this.formType = value;
      this.bank = '';
      this.bankid = '';
      this.weixinAccount = '';
      this.selectedBank = '';
      this.qrCode = null; // Reset qrCode when form type changes
    },
    onConfirm(value) {
      this.formType === 'bank' ? this.bank = value.text : this.selectedBank = value.text;
      this.bank_code = value.value;
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getBankList() {
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res => {
        if (res.code === 200) {
          this.banks = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    onUploadQRCode(file) {
  const formData = new FormData();
  // 确保 file.file 是文件对象
  if (file && file.file) {
    formData.append('file', file.file); 
  } else {
    console.error('Invalid file object:', file);
    return; // 处理错误
  }
  console.log(file.file)
  this.$http({
    method: 'post',
    url: 'upfile',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(res => {
    console.log(res);
    if (res.code === 200) {
      this.qrCode = res.data; // 存储上传的二维码URL
      this.$toast(res.msg);
    } else {
      this.$toast(res.msg);
    }
  }).catch(error => {
    console.error(error); // 捕获并打印错误
    this.$toast(this.$t('上传失败，请重试'));
  });
}
,
    getUserBankInfo() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.code === 200) {
          this.is_bind = res.data.is_bank;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    }
  },
  created() {
    if (!localStorage.getItem('token')) {
      this.$router.push({ path: '/Login' });
    } else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.form-switch {
  margin: 20px;
  font-size: 30px;
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-between; /* Adjusted for equal spacing */
}
.click-select {
  width: 50%;
}

.click-select span {
  width: 100%;
  padding: 20px;
  color: #333;
  display: inline-block; /* To ensure the text is centered properly */
  text-align: center; /* Center align the text */
  cursor: pointer; /* Pointer cursor for clickability */
  transition: background-color 0.3s ease; /* Smooth background change */
}

.active {
  background-color: #007aff; /* Highlight color for active selection */
  color: white;
}

.upload-container {
  text-align: center;
  margin: 20px 0; /* Add some spacing */
}
.van-button{
  padding: 40px;
  font-size: 30px;
}
</style>


<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  // background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  // background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #fff;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background:#cacacc;
}
.van-field{
  background: none;
  color: #fff;
}
.van-cell-group{
  background: none;
}
::v-deep .inputfiled .van-field__control {
  color: #FFF !important; /* 设置输入文字为白色 */
}
::v-deep .inputfiled .van-field__body {
  color: #FFF !important; /* 设置输入文字为白色 */
}
::v-deep .inputfiled .van-field__label{
  color: #FFF;
}
</style>
