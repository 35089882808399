<template>
	<div class="movie-hall">
		<van-nav-bar class="nav-bar" :title="$t('video.video')">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<van-tabs v-model="active" animated swipeable @change="OnChange">
			<van-tab v-for="(v,key) in videolitem" :key="key" :title="$t(`vedio.${v.name}`)" :name="v.key"></van-tab>
		</van-tabs>
		<swiper class="video_swiper" ref="swiper" :options="videoSwiperOption" @slideChange="itemChange">
			<swiper-slide v-for="(v,key) in videolitem" :key="key">
				<!-- <div class="movie-list-tab"> -->
				<div>
					<van-pull-refresh pulling-text="새로고침 성공" loosing-text="페이지 새로 고침" loading-text="새로고침 성공"
						success-text="새로고침 성공" v-model="isLoading" @refresh="onRefresh">
						<div class="hot-recommend-div">
							<van-list v-model="loading" :finished="finished" :immediate-check="false"
								:finished-text="$t('video.no_more')" @load="onLoad">
								<div class="list-item">
									<div class="movie-list-item" v-for="(v,key) in videolist" :key="key"
										@click="toPlayVideo(v.id)">
										<van-image class="cover_img" round :src="v.vod_pic">
											<template v-slot:loading>
												<van-loading type="circular" />
											</template>
										</van-image>
										<div class="movie-list-item-bottom">
											<div class="movie-time-div">
												<span>{{v.vod_name_yn_yu}}</span>
												<span>{{$t("video.play")}}:{{v.count}}</span>
											</div>
										</div>
									</div>
								</div>
							</van-list>

						</div>
					</van-pull-refresh>
				</div>
			</swiper-slide>
		</swiper>
		<!-- <div class="movie-list-tab">
			<van-pull-refresh pulling-text="새로고침 성공" loosing-text="페이지 새로 고침" loading-text="새로고침 성공"
				success-text="새로고침 성공" v-model="isLoading" @refresh="onRefresh">
				<div class="hot-recommend-div">
					<van-list v-model="loading" :finished="finished" :immediate-check="false"
						:finished-text="$t('video.no_more')" @load="onLoad">
						<div class="list-item">
							<div class="movie-list-item" v-for="(v,key) in videolist" :key="key"
								@click="toPlayVideo(v.id)">
								<van-image class="cover_img" round :src="v.vod_pic">
									<template v-slot:loading>
										<van-loading type="circular" />
									</template>
								</van-image>
								<div class="movie-list-item-bottom">
									<div class="movie-time-div">
										<span>{{v.vod_name}}</span>
										<span>{{$t("video.play")}}:{{v.count}}</span>
									</div>
								</div>
							</div>
						</div>
					</van-list>
		
				</div>
			</van-pull-refresh>
		</div> -->
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	export default {
		data() {
			return {
				active: 0,
				isLoading: false,
				loading: false,
				finished: false,
				refreshing: false,
				videolitem: [],
				videolist: [],
				number: 0,
				page: 0,
				count: 0,
				counts: 0,
				lime: 16,
				videoSwiperOption: {
					slidesPerView: 'auto',
					spaceBetween: 0,
					slidesPerGroup: 1,
				}
			};
		},
		methods: {
			back() {
				this.$router.push({
					path: 'home'
				});
			},
			getVideoClass() {
				this.$http({
					method: 'get',
					url: 'video_class'
				}).then(res => {
					this.videolitem = res.data;
				})
			},
			toPlayVideo(id) {
				if (!localStorage.getItem('token')) {
					this.$router.push({
						path: '/Login'
					})
				} else {
					this.$router.push({
						path: '/PlayVideo?id=' + id
					})
				}

			},
			itemChange() {
				this.active = this.$refs.swiper.swiper.activeIndex
				this.OnChange()
			},
			getVideoList() {
				this.$http({
					method: 'get',
					data: {
						id: this.active,
						page: this.page
					},
					url: 'video_list'
				}).then(res => {
					// this.videolist = this.videolist.concat(res.data.data);
					// this.count = res.data.count;
					// this.page++;
					this.videolist = this.videolist.concat(res.data.data);
					this.count = res.data.data.length;
					this.counts = res.data.data;
					this.page++;
					console.log('res.data.data', res)

				})
			},
			onLoad() {
				this.getVideoList();
				let timer = setTimeout(() => {
					if (this.refreshing) {
						this.videolist = [];
						this.refreshing = false;
					}
					this.loading = false;
					console.log('this.counts.length', this.counts.length)
					if (this.counts.length < this.lime) {
						this.finished = true;
					}
					this.finished && clearTimeout(timer); //清除计时器
				}, 500);
			},
			OnChange() {
				this.videolist = [];
				this.number = 0;
				this.page = 0;
				this.count = 0;
				this.getVideoList(); //获取视频列表

			},
			onRefresh() {
				setTimeout(() => {
					this.finished = false;
					this.loading = true;
					this.onLoad();
					this.isLoading = false;
					Toast(this.$t("reservation.refresh"));
				}, 500);
			},
		},
		created() {
			this.getVideoClass(); //获取视频类目
			this.OnChange()
		}
	};
</script>

<style lang='less' scoped>
	.page {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #29243D;
	}

	.nav-bar {
		background: linear-gradient(270deg, #323795, #4c206f);
		height: 100px;

	}

	.van-nav-bar {
		line-height: 50px;
	}

	::v-deep .van-tab {
		font-size: 30px;
		line-height: 100px;
	}

	::v-deep .van-nav-bar__title {
		max-width: 60%;
		margin: 0 auto;
		color: #ffffff;
		font-size: 35px;
	}

	::v-deep .van-nav-bar__content {
		height: 100px;
	}

	.movie-hall {
		display: flex;
		flex-direction: column;
		bottom: 100px;
		background: #29243D;
	}

	::v-deep .van-tabs__nav {
		background: linear-gradient(270deg, #323795, #4c206f);
	}

	::v-deep .van-tab {
		color: #ffffff;
		font-size: 30px;
	}

	::v-deep .van-tabs__line {
		bottom: 15px;
		width: 55px;
		height: 7px;
		border-radius: 0px;
		background-color: #ffffff;
	}

	::v-deep .van-tabs--line .van-tabs__wrap {
		height: 100px;
	}

	::v-deep .van-tabs__wrap--scrollable .van-tab {
		padding: 0 23px;
	}

	::v-deep .van-hairline--bottom::after {
		border-bottom-width: 0px;
	}

	.video_swiper {
		width: 100%;
		flex: 1;

		.swiper-slide {
			flex-shrink: 0;
			flex-grow: 0;
			flex-basis: 100%;
			justify-content: center;
			height: 100%;
			position: relative;
			transition-property: transform;
		}
	}

	.movie-list-tab {
		overflow: auto;
		height: 100%;
	}

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000;
		font-size: 35px;
	}

	.movie-list-tab .hot-recommend-div {
		height: 100%;
		margin: 10px auto;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
		//overflow: auto;
	}

	.list-item {
		display: flex;
		width: calc(100% - 50px);
		margin: 10px auto;
		align-items: flex-start;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.list-item .movie-list-item:nth-child(odd) {
		margin-right: 20px;
	}

	.movie-list-item .cover_img {
		border-radius: 20px;
		width: 335px;
		height: 290px;
	}

	.movie-list-item {
		margin-bottom: -10px;
	}

	.list-item .movie-list-item-bottom {
		position: relative;
		width: 335px;
		bottom: 42px;
	}

	.list-item .movie-list-item-bottom .movie-time-div {
		background-color: rgba(0, 0, 0, .4);
	}

	.list-item .movie-list-item-bottom>div {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
		color: #fff;
	}

	.list-item .movie-list-item .movie-time-div span:first-child {
		overflow: hidden;
		white-space: nowrap;
		width: 180px;
		padding-left: 8px;
		font-size: 25px;
	}

	.list-item .movie-time-div {
		color: #fff;
		border-radius: 0 0 20px 20px;
		height: 35px;
	}
</style>