<template>
	<div class="home-container">
		<van-nav-bar class="nav-bar" :title="$t('foorter.pickgirl')">
		</van-nav-bar>
		<div class="home-scroll">
			<div class="gift-wrapper" v-for="(item, key) in xuanfeilist" :key="key">
				<div class="girls-item">
					<div class="top-area">
						<div class="left-icon-area"><img :src=item.img_url style="width: 70px; height: 70px;"><span
								class="bottom-icon"><img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACjSURBVHgBpVGxDQIxDDwn+a8Z4dkANqBGQoIN6KnYACZ4CYk9UtHCCNkANuBrlMQ4DUgkEv/6K+27s88GBLxuJ+gBxavTFqF68vI8+0c2YGpASRaL7jK1QTBXEB1MuVm3CDjSZefgjZiJYeSpyqxeepEk0CFbS2EARpJr7QCyssbtt5UFJCuhgM2X4Tv4ykHR3YD4gXS7qDoUQHafxPNPoe8H34imLt/CGJNJAAAAAElFTkSuQmCC"
									style="margin-right: 2px; height: 9px; width: 13px;"><span>{{$t('Live streaming')}}</span></span></div>
						<div class="right-content">
							<div class="list-item"
								style=" width: 150px;  overflow: hidden;white-space: nowrap;  text-overflow: ellipsis;">
								<span
									style="font-size: 17px; float: left;display: inline-block; /* 让 span 能应用 text-overflow */ max-width: 100%;">{{ item.xuanfei_name }}</span><img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF+SURBVHgBfVNLUsJAEO2eBDYuzA2Ekxj3RPEEkqUFFOYEwAkIAuvgCUwZXYtH8ATGG2C50yRthyGYyYeuSjLT8/rN608QCkbdWQuS5g0k1AUkQ3oxBKJ30CMXfSfM41EJvlyNGOjCMUOc4FN/WiIga+nxp/fPBht+ve1h54w0czRrDAb2gYA6qzHLneSusTHor/MXk3V/ByBmOZfDJC7uco4bH1XsRaPOwuUURvvtFr5/2wJizVRQMc6h3vzc2oCTRk+w3CsF0hTb2nA9CpW9AFMAoaE4o6hVSxDpxbNTUQIhjqHOKs50Ls1nYRxMslaPoP042dBwoQ0udNoBsxAfpgp8xRUnNlAyL8ht8YA98HOtKoKNnANrwW1EGaBpbfRvQ6gwteUUYjBs63INNrO9SgWxx1MpLwgGF/KCpTyLuHVZtgSOFJHtO4tJqUhpOjtVwlP8CU/hy8BVCPYkJnu8QzqlHHb/xxSfhxs4lKEKlxKlFUdxJh3JF7/9fGBmf3HujRt/hGX+AAAAAElFTkSuQmCC"
									style="margin-right: 5px; margin-left: 5px; margin-top: 4px;"><img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADaSURBVHgBhVCxEYJAELw70dwOMHPUwBLsQFM1wA6UCtAK1A40wjERO7AEIzWTEsyBO+EHHkZg3OR3/nb3bhagAnIeLOXU31bNsCR2uyYjvRNOwh2cvvzinH4NATYmGQ+BFqUNKrFhjDnitkpAsOLHTOc+CxwzsQF8MJIUYnaodJyCGf87eqMQUOJCgTv8gYDclFbf7vbWxbQimGXfnD1X6mSdwIFXl26g7DKuDUTNYZ0hQJyUDShWSj9xM5uIxc5FONLbMhIyXIkEOEK7NX+oEuLKPQa6COSlfAHVCk8CO66bcAAAAABJRU5ErkJggg=="
									style="margin-right: 2px; width: 16px; height: 16px;">
							</div>
							<div class="list-item" style="margin-bottom: 3px;"><img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPCAYAAAA/I0V3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADTSURBVHgBjVKhEsIwDE13iEnk5CSfgEQikZNIJBLJJ/Apk5OTlZPIyklkXXnpBa4t4bbcvSVd3kubNkSKhRBa4ALstLxJiDVcAxyANuFMgtkY46MI5D08Y0vLxqKhwqdeKaAPt1IST8BKbGWdmSZqhDyIb9eImOzRNAu8rDPbKKJO/B24KXlV1ANO4occtytFr0J0Sna6/takmXvi2/Fl5s808AauklceFUKn/BuZH3vim0JlDo+kmxfBFPnFkXgyzpRPiAN6CL69G61sMo9W3iuzN/ZrQzaXxPyyAAAAAElFTkSuQmCC"
									style="margin-right: 2px; float: left; margin-top: 4px;"><span
									style="font-size: 14px;">{{$t('Paid already')}}<span style="color: rgb(255, 153, 0);">{{ item.money
                                        }}</span><span>{{$t('money deposit')}}</span></span></div>
							<div class="list-item"><img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAQCAYAAAAiYZ4HAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADnSURBVHgBdVIhEoMwEEwyFZVIJLKSJ/QJSCSS5/QJlZVIJLKyMrISGYmje/TC7IRwM0eS27vN5g5rElvX9Yql1ONsrV0Yt5RYYGngVcLxgU8oDHsBkoWxg19N3uSWJ4pmp4GWkhdl9fCgMcFakXvBp8ahUCAoUyCZneLitdxwo6uHmLzp/e8HwkuX6A7maBwrXBrIFFS0X6RgpkCjug294U64tzqoPmH/Rs0kOeBND0tMvTmfw969bQ7ajdGc2xi7FwcnRTKsdyZZfgsfD44RACPpF/OITZzjMowv1ZwO7dykCdq9g/0AxExQ+t638NUAAAAASUVORK5CYII="
									style="margin-right: 2px;"><span
									style="font-size: 14px;">{{ $t(item.address) }}</span><img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEESURBVHgBnZBBTsMwEEXHU4WwLDfIEXIEegSWBSEwogLvygkIN4BVpCCwuoi6RJygHIEj+AZkm4bO4KlaYUJVNf0Le/zHb8YeBYGKokzVgvojc/4R+nlukwjjtKH60xjtxFOyWJ/gXvzGDOnqrptTPZBL9nl6T8TZugiiyvRo+LAEX4tyxqyO4a8qJnhXCBctH/CgPlLW2j7N4y/oImKNTQMJ7CGMInDQUQTkUGstf5l04JxMHSXqHdZjMXah/DQz2ZegdF1884kPq20QMz9d3ZxOVgV+ZfNpQsgzHyaboOvbs/H6jGFSm6FDUoPWsysCuAuhfx1DveTlJStIG8ZH4wu28z/x0mkfNqt++gAAAABJRU5ErkJggg=="
									style="margin-right: 2px; margin-left: 15px;"></div><img src="img/ywc.png" style="position: absolute;     right: -25px;
    top: -20px;">
						</div>
					</div>
					<div class="ant-divider"></div>
					<div class="bottom-area">
						<div class="btm-left">
							<!-- <img class="img-cover" @click="profile(item.id)" :src=item.img_url2 style="width: 100%; height: 140px; border-radius: 8px;"> -->
							<img class="img-cover" :src=item.img_url2 style="width: 100%; height: 140px; border-radius: 8px;">
						</div>
						<div class="btm-right">
							<div style="margin-bottom: 16px;"><span class="signspan">{{ $t('签名') }}：{{ $t(item.sign) }}</span></div>
							<div class="tags">
								<span v-for="(tag, index) in item.label" :key="index">{{ $t(tag) }}</span>
							</div>
							<div
								style="position: absolute; margin-top: 8px; color: black; background: white; border-radius: 16px; height: 28px; display: inline-block; padding: 4px 18px;">
								<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHpSURBVHgBnZK/bhNBEMa/2TPHn0TJuSElSQdUGIkScaaCKs4TxOmo4CJKmkS0FFdRBiNeIDyBTUUZR0KCzo5SUtwhhOTc3uwwe/gUoyiRk5VOe7M785uZb5ZwxXW8k8VE6BhD6w2WjcZlgkc7o2iBljZZpANwTEIC9jcMmgfw881xLEH4kkRiAUUaf6THyxBEAP1aebvSPLeiUTKKFm+FrwCXwJEGFVVSEexqO2pLImoT0dCf/wfKNJgDjtEwCkAMLqpzT9CggQW2QsGmOCTalAgpBzgFZcn32JlgXch2DSGS0s32nJfkdm+/u5tmr398cDBdM73wFApoUIGy5OCB5usbZ6fZSaoWIOpH+6rJ9jVM8iz51gdzHMBBTqsESjr0m2mmLS3tZE2dtoh5n7ggcvYIZdGO0vsbmExgHPUN8xPiEsQWhq3oTuqbN9N747r9M0urjDRBnr34uhqYsE+QVfhR67xn/bTqz0vvH3XOiF0vD/H7daE9Z+0dmGpcOiD6184UYwQHtXXxgyyLNa8nOX1BVfXiNUS9M+FL7Wou4qhmy4bLcUPQAtuP+o9APzPVarEsh3OBVFRt8aQd7j0+XOg97aq42wrIPcgohHrtfC5Q+PvPw5u95+PavvHpWeostxQ2VNhg1vcvR8HkJ9Vp6wsAAAAASUVORK5CYII="
									style="float: left; margin-top: 2px; margin-right: 3px;"> <span
									style="font-size: 13px; color: black; cursor: pointer;"
									@click="showContactDialog">{{ $t('请联系客服') }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<van-dialog v-model="showDialog" :title="$t('提示')" style="font-size: 14px;" :width="'50%'" :height="'200px'"
			@close="showDialog = false">
			<div style="max-height: 300px;height: 50px;margin-top: 10px; text-align: center; overflow-y: auto;">
				{{ $t('请联系约爱红娘激活权限') }}</div>
			<template #footer>
				<van-button type="primary" style="font-size: 16px;" @click="showDialog = false">{{ $t('确定') }}</van-button>
			</template>
		</van-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				xuanfeilist: [],
				showDialog: false, // 控制对话框显示的变量
			};
		},
		methods: {
			profile(id) {
				this.$router.push({
					path: '/profile?id=' + id + '&name=' + this.vod_name + '&adsid=' + this.$route.query.id
				});
			},
			gotoMenu(router) {
				this.$router.readdress(router);
			},
			toLink(item) {
				this.$router.push('/Detail');
				window.localStorage.setItem('detail', JSON.stringify(item));
			},
			getxuanfeilist() {
				this.$http({
					method: 'get',
					url: 'xuanfeilist',
					data: {
						id: this.$route.query.id
					}
				}).then(res => {
					console.log(res, 'this.xuanfeilist');
					this.xuanfeilist = res.data;
				});
			},
			showContactDialog() {
				this.showDialog = true; // 显示对话框
			}
		},
		mounted() {
			console.log(this.$store.state.baseInfo.name, 'ussssssss');
		},
		created() {
			this.getxuanfeilist();
		}
	}
</script>


<style lang='less' scoped>
	@notice-bar-size: 30px;
	@movie-list-item-bottom-size: 25px;


	.home-container {
		position: absolute !important;
		top: 0;
		left: 0;
		right: 0;
		background-color: #29243d;
		overflow-y: auto;
		padding-bottom: 50%;
	}

	.home-scroll {
		padding: 10px;
	}


	.noticeContent {
		padding: 20px;
	}

	.gift-wapper {
		width: 100%;
		height: 100%;
	}

	.girls-item {
		border-radius: 1rem;
		padding: 1rem 1rem;
		margin-top: .53333rem;
		background: #4f3460;
	}

	.top-area {
		position: relative;
	}

	.ant-divider {
		box-sizing: border-box;
		padding: 0;
		color: rgba(0, 0, 0, .85);
		font-size: .37333rem;
		font-variant: tabular-nums;
		line-height: 1.5715;
		list-style: none;
		font-feature-settings: "tnum";
		border-top: .02667rem solid rgba(0, 0, 0, .06);
		margin: .42667rem 0;
		border: .08667rem solid hsla(0, 0%, 100%, .2);
	}

	.left-icon-area {
		position: relative;
		float: left;
		width: 25%;
		text-align: center;
	}

	.left-icon-area img {
		border-radius: 20rem;
	}

	.right-content {
		margin-left: 2%;
		display: inline-block;
		color: #fff;
	}

	.bottom-icon {
		font-size: 0.6rem;
		display: inline-block;
		margin: auto;
		position: absolute;
		bottom: -.13333rem;
		left: 0;
		right: 0;
		color: #fff;
		text-align: center;
		width: 130px;
		height: 40px;
		background: linear-gradient(180deg, #802a7d, #2c243f);
		border-radius: .50667rem;
	}

	span {
		color: #fff
	}

	.list-item {
		line-height: 1.4rem;
	}

	.bottom-area {
		position: relative;
		height: 8.5rem;
	}

	.btm-left {
		float: left;
		width: 40%;
	}

	.img-cover {
		object-fit: cover;
		width: 100%;
		height: 3.73333rem;
		border-radius: .21333rem;
	}

	.btm-right {
		margin-left: 4%;
		width: 56%;
		color: #fff;
		display: inline-block;
	}

	.tags:first-child span:nth-child(20),
	.tags:nth-child(2) span:first-child {
		background: #dea52b;
	}

	.tags:nth-child(2) span:nth-child(2) {
		background: #2dabd2;
	}

	.tags:nth-child(2) span:nth-child(3) {
		background: #e9335c;
	}

	.tags:nth-child(2) span:nth-child(4) {
		background: #dea52b;
	}

	.tags span {
		display: inline-block;
		font-size: 24px;
		border-radius: 1rem;
		padding: .3rem .4rem;
		margin-right: .3rem;
		margin-bottom: .3rem;
	}

	.signspan {
		font-size: 22px;
		;
	}
</style>